import Drawer from '@/components/drawers'
import { validationMixin } from "vuelidate"
import { required, numeric } from "vuelidate/lib/validators"
import Loading from "@/components/widgets/Loading.vue"

export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String
    },
    id: {
      type: String
    }
  },
  data() {
    return {
      loading: false,
      drawerOpened: false,
      sellProductList:[],
      form: {
        code: null,
        name: null,
        description: null,
        main_product_id: null,
      }
    }
  },
  components: {
    Loading,
    Drawer
  },
  watch: {
    'opened'(n, o) {
      this.onInitSellProductTypeList()

      if (n) {
        this.showFileInput = true
        this.drawerOpened = n
        if (this.mode === 'update') {
          this.onInitData()
        }
      }
    }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      code: { required },
      name: { required },
      main_product_id: { required },
    },
  },
  methods: {
    resetForm () {
      this.form.code = null
      this.form.name = null
      this.form.main_product_id = null
      this.form.description = null

      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    formBuilder(obj) {
      this.form.code = obj.data?.code ?? ''
      this.form.name = obj.data?.name ?? ''
      this.form.main_product_id = obj.data?.main_product_id ?? null
      this.form.description = obj.data?.description ?? ''

      this.$v.form.$touch()
    },
    onCloseDrawer(event) {
      this.drawerOpened = false
      this.resetForm()
      this.$emit('close-event', { 
        status: true
      })
    },
    async onInitData() {
      try {
        const result = await this.$axios.get(
          `${this.$host}/api/v1/sku/${this.id}`
        )
        if (result.status === 200) {
          this.formBuilder(result)
        }
      } catch (error) {
        this.onExceptionHandler(error.response.data.message);
      }
    },
    async onInitSellProductTypeList() {
      try {
        const result = await this.$axios.get(
          `${this.$host}/api/v1/product-types`
        )
        if (result.status === 200) {
          const data = result.data.data
          this.sellProductList = data
        }
      } catch (error) {
        this.onExceptionHandler(error.response.data.message);
      }
    },
    async onSubmitForm() {
      try {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        this.loading = true
        const formData = new FormData()
        formData.append('code', this.form.code?? '')
        formData.append('name', this.form.name?? '')
        formData.append('main_product_id', this.form.main_product_id?? '')
        formData.append('description', this.form.description?? '')

        let result = await this.$axios.post(this.$host + "/api/v1/sku" + (this.mode === 'update' ? '/' + this.id  : ''), formData)

        this.loading = false   
        setTimeout(() => {
          if (result.status === 201 || result.status === 200) {
            this.$swal({
              width: "29rem",
              padding: '1.825em',
              html: '<div class="d-flex flex-row align-items-center">' +
                '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
                '<div class="d-flex flex-column">'+
                '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
                `<span class="text-base text-left text-gray-500">${result.data?.message ?? "บันทึกข้อมูลเรียบร้อยแล้ว"}</span>`+
                '</div>'+
              '</div>',
                allowOutsideClick: false,
                focusConfirm: false,
                customClass: {
                  popup: 'swal2-custom-rounded',
                  closeButton: 'text-3xl swal2-custom-close',
                  actions: 'justify-content-end',
                  contant: 'd-flex flex-row justify-content-around px-2',
                  title: 'd-none',
                  confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                  cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
                },
                showCancelButton: false,
                confirmButtonText: "ตกลง",
                cancelButtonText: "ยกเลิก",
                showCloseButton: true,
            }).then(result => {
              this.loading = false
              this.onCloseDrawer()
            })
          }
        }, 500)
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    },
  }
}
